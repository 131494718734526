import { saasModernTheme } from 'common/theme/saasModern';
import styled from 'styled-components';

export const SectionInfo = styled.section`
  padding: ${saasModernTheme.space[2]}px;
`;

export const InfoHeader = styled.header`
  text-align: center;
`;
export const ContainerCard = styled.div`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box; /* Ensure padding is included in the total width and height */
  gap: 16px;
  @media (max-width: 1024px) {
    max-width: 100%;
    overflow-y: scroll;
  }
`;

export const ContainerInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  @media (max-width: 1024px) {
    max-width: 100%;
  }
  @media (max-width: 920px) {
    max-width: 100%;
    flex-direction: column;
  }
`;

export const Aside = styled.aside`
  flex: 1;
  width: 100%;
  position: relative;
`;

export const Video = styled.video`
  width: 100%;
  height: 100%;
  border-radius: ${saasModernTheme.radius[3]}px;
`;

export const Article = styled.article`
  flex: 1;
`;
